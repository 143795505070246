import { Component, Inject, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-idle-dialog',
  templateUrl: './idle-dialog.component.html',
  styleUrl: './idle-dialog.component.scss',
})
export class IdleDialogComponent {
  timer: Signal<number | undefined> = toSignal(this.data);

  constructor(
    private readonly dialogRef: MatDialogRef<IdleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: Observable<number>,
  ) {}

  onLogout() {
    this.dialogRef.close(false);
  }

  onContinue() {
    this.dialogRef.close(true);
  }
}
