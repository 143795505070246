import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE } from '@core/injectors';
import { environment } from '@environments/environment';
import { AuthenticationService } from '@features/server-side-authentication';
import { filter, interval, startWith, Subscription, tap } from 'rxjs';

const DEFAULT_TIMEOUT_TIME = 10 * 60 * 1000; // 10 min

@Injectable()
export class IdleTokenService {
  private readonly STORAGE_KEY = 'tku';
  private readonly TIMER_INTERVAL = 10 * 1000; // 10s

  protected readonly timeoutTime = environment.restoreTokenTime || DEFAULT_TIMEOUT_TIME;

  private dateTimeStart: Date | null = null;
  private timer = 0;

  private intervalRestoreToken!: Subscription;

  constructor(
    @Inject(LOCAL_STORAGE) private readonly storage: Storage,
    private readonly authenticationService: AuthenticationService,
  ) {
    this.onInitDetection();
  }

  private onInitDetection() {
    const activity: string | null = this.storage.getItem(this.STORAGE_KEY);

    if (activity) {
      this.dateTimeStart = new Date(activity);
      const currentTime: Date = new Date();

      if (this.dateTimeStart && currentTime.getTime() - this.dateTimeStart.getTime() >= this.timeoutTime) {
        this.restoreToken();
      }
    }
  }

  public restoreToken() {
    this.storage.setItem(this.STORAGE_KEY, new Date().toISOString());
    this.authenticationService.restoreToken().subscribe();
  }

  public startWatching() {
    this.storage.setItem(this.STORAGE_KEY, new Date().toISOString());

    // Restore token every X minutes
    // This is used to extend the JWT token life
    this.intervalRestoreToken = interval(this.TIMER_INTERVAL)
      .pipe(
        startWith(0),
        tap(() => (this.timer += this.TIMER_INTERVAL)),
        filter(() => this.timer >= this.timeoutTime),
      )
      .subscribe(() => {
        this.restoreToken();
        this.timer = 0;
      });
  }

  public stopWatching() {
    this.intervalRestoreToken?.unsubscribe();
  }
}
