<main>
  <div class="pt-3 d-grid">
    @if (isLoading()) {
      <mat-progress-bar mode="query"></mat-progress-bar>
    }
    @if (organizationData$ | async; as organizationData) {
      @if (isDetailedView()) {
        <div class="row align-items-center">
          <div class="col d-grid titleSection">
            <span class="align-items-center organizationName">{{ organizationData.organization.name }}</span>
            <mat-chip
              class="mx-2 client-type-chip"
              [ngClass]="organizationData.organization.subscription.customer_type | statusColor"
            >
              {{ organizationData.organization.subscription.customer_type }}
            </mat-chip>
            <span class="customerType">
              Type of customer:
              <b>{{ organizationData.organization.subscription.package_level }}</b>
            </span>
          </div>
          <div class="col d-grid actionsSection">
            <!-- todo: waiting for requirements -->
            <!-- <button mat-stroked-button color="primary" disabled="true" matTooltip="to be implemented">
              Add as favorites
            </button> -->
            <button mat-flat-button color="primary" [routerLink]="['/organizations', selectedOrganization(), 'home']">
              View details
            </button>
          </div>
        </div>
      }
      <div class="row my-2">
        @for (statistic of statistics(); track statistics.name) {
          <app-statistics-tile-organizations class="col" [statistic]="statistic"></app-statistics-tile-organizations>
        }
      </div>
      @if (isDetailedView()) {
        <div class="row my-2 g-2 d-grid productsSection">
          @for (product of products(); track product) {
            <app-product-tile-organizations class="col" [product]="product"></app-product-tile-organizations>
          }
        </div>
      }
      <div class="row my-2">
        <app-status-table-organizations class="col" [data]="dataSources()"></app-status-table-organizations>
        <app-status-table-organizations class="col" [data]="connectors()"></app-status-table-organizations>
      </div>
      <div class="row my-2">
        <app-zendesk-tickets-table [organizationId]="selectedOrganization()" class="col-6"></app-zendesk-tickets-table>
      </div>
    }
  </div>
</main>
