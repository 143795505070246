import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject, Subscription, map, tap } from 'rxjs';
import { IdleTokenService } from '../../services/idle-token/idle-token.service';
import { IdleService } from '../../services/idle/idle.service';
import { IdleDialogComponent } from '../idle-dialog/idle-dialog.component';

@Component({
  selector: 'app-idle',
  templateUrl: './idle.component.html',
  styleUrl: './idle.component.scss',
})
export class IdleComponent implements OnInit, OnDestroy {
  idleTimer: Subject<number> = new Subject<number>();

  private timerStartSubscription!: Subscription;
  private timeoutSubscription!: Subscription;

  private dialogRef!: MatDialogRef<IdleDialogComponent, any> | null;

  constructor(
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly idleService: IdleService,
    private readonly idleTokenService: IdleTokenService,
  ) {}

  ngOnInit() {
    this.idleService.startWatching();
    this.idleTokenService.startWatching();

    // Start watching when user idle is starting
    this.timerStartSubscription = this.idleService
      .onTimerStart()
      .pipe(
        tap(() => this.showIdleModal()),
        map((count) => new Date(0, 0, 0).setSeconds(count || 0)),
      )
      .subscribe((timer) => this.idleTimer.next(timer));

    // Start watch when time is up
    this.timeoutSubscription = this.idleService.onTimeout().subscribe(() => {
      this.dialogRef?.close();
      this.dialogRef = null;
      this.onLogout();
    });
  }

  ngOnDestroy() {
    if (this.timerStartSubscription) {
      this.timerStartSubscription.unsubscribe();
    }

    if (this.timeoutSubscription) {
      this.timeoutSubscription.unsubscribe();
    }

    this.idleService.stopWatching();
    this.idleTokenService.stopWatching();

    this.dialogRef?.close();
    this.dialogRef = null;
  }

  showIdleModal() {
    if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(IdleDialogComponent, {
        width: '600px',
        maxWidth: '650px',
        data: this.idleTimer.asObservable(),
      });
      this.dialogRef.afterClosed().subscribe((state: boolean) => {
        this.onDialogCallback(state);
      });
    }
  }

  onDialogCallback(state: boolean) {
    if (state) {
      this.onContinue();
    } else {
      this.onLogout();
    }
    this.dialogRef = null;
  }

  onContinue() {
    this.idleService.stopTimer();
    this.idleTokenService.restoreToken();
  }

  onLogout() {
    this.idleService.stopWatching();
    this.router.navigate(['/auth', 'logout']);
  }
}
